import { Component } from "react";
import { Container, Grid, Typography, Box } from "@mui/material";

import background from "../../assets/images/umbrella-1-30-min.png";

import { exampleExchangeSelfInsure } from "../../views/Landing/landingData";

const styles = {
  thirdContainer: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#000",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: "600px",
    alignItems: "center",
    margin: 0,
    padding: 0,
  },
  slideHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    
    textShadow: "2px 2px #000",
  },
  slideContentHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    textShadow: "1px 1px #000",
    color: "primary.contrastText",
    
  },
  slideContent: {
    display: "inline-block",
    textShadow: "1px 1px #000",
    
  },
  slideContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default class ExchangeSelfInsure extends Component {
  render() {
    return <>{GetExchangeSelfInsure()}</>;
  }
}

function GetExchangeSelfInsure(props) {
  return (
    <Box style={styles.thirdContainer}>
      <Container
        variant="primary"
        style={styles.slideContainer}
        disableGutters={true}
      >
        <Grid container variant="primary" spacing={0}>
          <Grid
            item
            style={styles.slideHeader}
            xs={12}
            sx={{
              alignItems: "center",
              padding: 1.1,
              textAlign: "center",
              marginBottom: 1,
            }}
          >
            <Typography variant="h1" sx={{ display: "inline" }}>
              How Would an Exchange
              <Typography
                variant="h1"
                sx={{ display: "inline", color: "primary.contrastText" }}
              >
                &nbsp;Self-Insure
                <Typography
                  variant="h1"
                  sx={{ display: "inline", color: "#FFF" }}
                >
                  , This Could Be 
                  <Typography
                    variant="h1"
                    sx={{ display: "inline", color: "primary.contrastText" }}
                  >
                    &nbsp;Any Person/Entity
                    <Typography
                      variant="h1"
                      sx={{ display: "inline", color: "#FFF" }}
                    >
                      &nbsp;Including DAOs
                    </Typography>
                  </Typography>
                </Typography>
              </Typography>
            </Typography>
          </Grid>
          <Box
            sx={{
              padding: { xs: 1, md: 2 },
              border: "#4A4A4A solid 2px",
              margin: 1,
              marginRight: 4,
              marginLeft: 4,
              borderRadius: 5,
              backgroundColor: "rgba(255,255,255,0.15)",
            }}
          >
            <ol>
              {exampleExchangeSelfInsure.map((item, i) => (
                <ExchangeSelfInsureItem key={i} item={item} />
              ))}
            </ol>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
}

function ExchangeSelfInsureItem(props) {
  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: { xs: 1, md: 3 },
        margin: 1,
        marginRight: 4,
        marginLeft: 4,
      }}
    >
      <Typography variant="h4" style={styles.slideContent}>
        <li>{props.item.step}</li>
      </Typography>
    </Grid>
  );
}
