import { Component } from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Button, Container, Grid, Typography, Box } from "@mui/material";

import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import background from "../../assets/images/blockchain-2-30-min.png";

const styles = {
  slideMaster: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#000",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: "600px",
    alignItems: "center",
    margin: 0,
    paddingTop: 1,
    paddingBottom: 1,
  },
  slideHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    
    textShadow: "2px 2px #000",
  },
  slideContentHeader: {
    display: "inline",
    textShadow: "1px 1px #000",
    color: "primary.contrastText",
    
  },
  slideContent: {
    display: "inline-block",
    textShadow: "1px 1px #000",
    
  },
  slideContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default class HowPanaWorks_2 extends Component {
  render() {
    return (
      <Box style={styles.slideMaster}>
        <Grid
          container
          variant="primary"
          spacing={0}
          sx={{ width: "100%", height: "100%" }}
        >
          <Grid
            item
            style={styles.slideHeader}
            xs={12}
            sx={{
              alignItems: "center",
              paddingTop: 1,
              textAlign: "center",
            }}
          >
            <Typography variant="h1" sx={{ display: "inline" }}>
              How Pana
              <Typography
                variant="h1"
                sx={{ display: "inline", color: "primary.contrastText" }}
              >
                &nbsp;Works
              </Typography>
              <Typography variant="h2" sx={{ color: "#FFF" }}>
                Step #2
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {GetHowPanaWorks()}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

function GetHowPanaWorks(props) {
  return (
    <Container
      variant="primary"
      style={styles.slideContainer}
      disableGutters={true}
    >
      <Grid
        container
        variant="primary"
        wrap="nowrap"
        sx={{
          width: "100%",
          display: "flex",
          height: "100%",
          alignItems: "flex-end",
        }}
      >
        <Grid
          item
          xs={2}
          md={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            height: 150,
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h2"
            style={styles.slideContent}
            sx={{ transform: "rotate(-90deg)", letterSpacing: 10 }}
          >
            TIME
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "rgba(255,255,255,0.25)",
            height: 25,
            justifyContent: "center",
            borderRadius: 5,
            margin: 1,
          }}
        >
          <Box sx={{ transform: "rotate(-90deg)" }}>
            <DoubleArrowRoundedIcon sx={{ transform: "scale(1)" }} />
          </Box>
        </Grid>
        <Grid
          item
          xs={3}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "rgba(255,255,255,0.25)",
            height: 75,
            justifyContent: "center",
            borderRadius: 5,
            paddingTop: 1,
            margin: 1,
          }}
        >
          <Box sx={{ transform: "rotate(-90deg)" }}>
            <DoubleArrowRoundedIcon sx={{ transform: "scale(4)" }} />
          </Box>
        </Grid>
        <Grid
          item
          xs={3}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "rgba(255,255,255,0.25)",
            height: 125,
            justifyContent: "center",
            borderRadius: 5,
            margin: 1,
            paddingTop: 1,
          }}
        >
          <Box sx={{ transform: "rotate(-90deg)" }}>
            <DoubleArrowRoundedIcon sx={{ transform: "scale(7)" }} />
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        variant="primary"
        spacing={0}
        sx={{ width: "100%", display: "flex", flexDirection: "row" }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            borderBottom: "6px dotted #fff",
            borderTop: "6px dotted #fff",
            paddingLeft: { xs: 0, md: 15 },
            paddingRight: { xs: 0, md: 3 },
          }}
        >
          <Grid
            container
            variant="primary"
            spacing={0}
            sx={{ width: "100%", display: "flex", flexDirection: "row" }}
          >
            <Grid
              item
              xs={2}
              md={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h3"
                style={styles.slideHeader}
                sx={{
                  letterSpacing: 2,
                }}
              >
                &#8679;&#8679;&#8679;
              </Typography>
            </Grid>
            <Grid
              item
              xs={8}
              md={10}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h3"
                style={styles.slideHeader}
                sx={{
                  letterSpacing: 10,
                }}
              >
                Yield Generated from Premiums
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              md={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h3"
                style={styles.slideHeader}
                sx={{
                  letterSpacing: 2,
                }}
              >
                &#8679;&#8679;&#8679;
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        variant="primary"
        wrap="nowrap"
        sx={{ width: "100%", display: "flex", height: "100%" }}
      >
        <Grid
          item
          xs={2}
          md={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h2"
            display="block"
            style={styles.slideContent}
            sx={{ transform: "rotate(-90deg)", letterSpacing: 1 }}
          >
            Premiums<br></br>Paid
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "rgba(255,255,255,0.25)",
            justifyContent: "center",
            borderRadius: 5,
            margin: 1,
            padding: 1,
          }}
        >
          <AccountBalanceRoundedIcon sx={{ transform: "scale(7)" }} />
        </Grid>
        <Grid
          item
          xs={3}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "rgba(255,255,255,0.25)",
            height: 250,
            justifyContent: "center",
            borderRadius: 5,
            margin: 1,
            padding: 1,
          }}
        >
          <AccountBalanceRoundedIcon sx={{ transform: "scale(7)" }} />
        </Grid>
        <Grid
          item
          xs={3}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "rgba(255,255,255,0.25)",
            justifyContent: "center",
            borderRadius: 5,
            margin: 1,
            padding: 1,
          }}
        >
          <AccountBalanceRoundedIcon sx={{ transform: "scale(7)" }} />
        </Grid>
      </Grid>
    </Container>
  );
}
