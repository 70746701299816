export const assetPoints = ["The flexibility of sovereign ownership and liquidity of NFT assets",
  "The power of asset ownership by turning recurring principal into owned, yielding crypto assets",
  "The option of parametric insurance valuations for NFT assets when parametric contracts associated with an NFT are triggered"];

export const infoCards = [
     {
        "title":"Opportunity",
        "content":"60% of the world's population are low-income with few insurance and asset ownership options",
        "icon": 'eye'
     },
     {
        "title":"Traditional Insurance Gap",
        "content":"Traditional carriers cannot create the efficiency or provide asset ownership options that are profitable enough for the carrier",
        "icon": 'gap'
     },
     {
        "title":"Blockchain Innovation",
        "content":"Leveraging high-speed, decentralized blockchain technology (Ethereum L2s), unique opportunities are created for those prevented by today's high paywalls ",
        "icon": 'light'
     },
     {
        "title":"Insurance Innovation",
        "content":"Leveraging the liquidity of NFTs and the sweeping hedge of a parametric policy, parametric insurance can be sustainably offered to everyone",
        "icon": 'umbrella'
     },
     {
        "title":"Real-World Track Record",
        "content":"Led by industry advisors and a team who has successfully delivered global, real-world solutions – there is real opportunity to extend DeFi globally to everyone by offering insurance as an asset",
        "icon": 'badge'
     }
  ];

  export const insuranceProblems = [
   {
      "title" : "Premiums Are Expenses",
      "description" : "Rather than being an investment that gains value, premiums are expenses"
   },
   {
      "title" : "Claims Are Intentionally Denied",
      "description" : "Traditional insurance companies are incentivized to deny claims - even DAOs like Nexus Mutual are incentivized to deny claims"
   },
   {
      "title" : "Covered Are Incentivized to Make Claims",
      "description" : "If the claim is legitimate, taking the payout is the only way to get a return on insurance expenses"
   },
   {
      "title" : "Parametric Insurance Is Impossible",
      "description" : "Broad parameters that empower all covered participants to receive a payout is financially impossible"
   },
   {
      "title" : "Insuring Frequently Occurring Events is Impossible",
      "description" : "Defining the most narrow, infrequent parameters possible are in the best interest of the traditional insurance company"
   },
   {
      "title" : "Small or Variable Premiums is Impossible",
      "description" : "Intermediaries, fees, and static commitments make it impossible to facilitate small or variable payment structures"
   }
];

export const insuranceSolutions = [
   {
      "title" : "Premiums Are Investments",
      "description" : "Premiums are invested into an NFT asset that has an internal wallet that accumulates token value"
   },
   {
      "title" : "Claims Are 100% Honored",
      "description" : "Participants can sell their NFT back to the DAO for a payout when an event occurs, no denials"
   },
   {
      "title" : "Covered Are Incentivized to Hold",
      "description" : "Even if the claim is legitimate, because premiums are investments - there is potential for a higher return if covered participants hold"
   },
   {
      "title" : "Parametric Insurance Is Possible",
      "description" : "Broad parameters that empower all covered participants to receive a payout is not only possible, but an intentional feature"
   },
   {
      "title" : "Insuring Frequently Occurring Events is an Important Feature",
      "description" : "Providing frequent opportunities to claim is important to enable participants to maximize value and protocol composability within DeFi"
   },
   {
      "title" : "Receiving Small or Variable Premiums is Key",
      "description" : "In order to serve the underserved, small or variable premiums are 100% supported because each payment simply provides rights to the NFT treasury"
   }
];

export const exampleExchangeSelfInsure = [
   {
      "step" : "Partner with Pana Protocol to create custom parametric exchange events"
   },
   {
      "step" : "15% of exchange fees rotated into Pana Protocol"
   },
   {
      "step" : "85% of fees rotated out of Pana Protocol using interest free loan"
   },
   {
      "step" : "100% of loaned fees returned to Pana Protocol when parametric event occurs to collect claim value"
   }
];