import { FC } from "react";
import { Typography, Link } from "@mui/material";

import { LINK_MONA } from 'src/constants';

export const ArtByMona: FC = () => {
  return (
    <Link href={LINK_MONA} target="_blank">
      <Typography
        sx={{
          opacity: 0.2,
          position: "absolute",
          bottom: 0,
          right: 10,
          color: "inherit",
        }}
      >
        Art by Mona
      </Typography>
    </Link>
  );
};

export default ArtByMona;
