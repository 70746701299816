export const assetPoints = ["The flexibility of sovereign ownership and liquidity of NFT assets",
  "The power of asset ownership by turning recurring principal into owned, yielding crypto assets",
  "The option of parametric insurance valuations for NFT assets when parametric contracts associated with an NFT are triggered"];

export const teamData = [
     {
        "name":'HolyBasilOnly',
        "description":'A full-stack developer leading insurance UI’s and Infura integrations ',
        "linkedin": 'https://www.linkedin.com/in/shahkeval88/'
     },
     {
        "name":'zxriptor',
        "description":'A core developer with skills ranging from AIML to smart contracts ',
        "linkedin": 'https://www.linkedin.com/in/denispakizh/'
     },
     {
        "name":'Bindusara',
        "description":'A leader driving projects from inception to production ',
        "linkedin": 'https://www.linkedin.com/in/arun-krishnan-45466497/'
     },
     {
        "name":'Vitashoka',
        "description":'A core developer spinning the UI and Infura merry-go-round ',
        "linkedin": 'https://www.linkedin.com/in/tdaley/'
     },
     {
        "name":'Chandragupta',
        "description":'A core developer with a zeal to use blockchain for building a solution suitable for mass adoption ',
        "linkedin": 'https://www.linkedin.com/in/vaibhavshah846/'
     },
     {
        "name":'Kshatriyan',
        "description":'Smart contract developer who brings much needed Libra to our protocol ',
        "linkedin": 'https://www.linkedin.com/in/preeth-gunasekaran/'
     },
     {
        "name":'Dasharatha',
        "description":'Smart contract developer and always a spiritual Taurus when it comes to crypto ',
        "linkedin": 'https://www.linkedin.com/in/sundarparamasivam/'
     },
     {
        "name":'cg125',
        "description":'Core development leader providing a link back to our external insurance efforts with NeuralMetrics and Web3 ',
        "linkedin": 'https://www.linkedin.com/in/sathish-kumar-manimuthu-388721a/'
     },
     {
        "name":'HaciricaH',
        "description":'Key mentor helping guide protocol development, tokenomics and general leadership ',
        "linkedin": 'https://www.linkedin.com/in/marcusdaley/'
     },
  ];