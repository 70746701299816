import { Component } from 'react';
import Typography from '@mui/material/Typography';
import { Card, CardContent, Link, } from "@mui/material";

import IconEye from 'src/components/Icons/eye';
import IconGap from 'src/components/Icons/gap';
import IconUmbrella from 'src/components/Icons/umbrella';
import IconBadge from 'src/components/Icons/badge';
import IconLight from 'src/components/Icons/light';
import LinkedInIconWhite from "src/components/Icons/linkedin_white";

import AvatarVitashoka from "src/views/Team/images/AvatarVitashoka";
import AvatarHolyBasilOnly from "src/views/Team/images/AvatarHolyBasilOnly";
import Avatarzxriptor from "src/views/Team/images/Avatarzxriptor";
import AvatarBindusara from "src/views/Team/images/AvatarBindusara";
import AvatarChandragupta from "src/views/Team/images/AvatarChandragupta";
import AvatarThenaliRamen from "src/views/Team/images/AvatarThenaliRamen";
import AvatarKshatriyan from "src/views/Team/images/AvatarKshatriyan";
import AvatarDasharatha from "src/views/Team/images/AvatarDasharatha";
import Avatarcg125 from "src/views/Team/images/Avatarcg125";
import AvatarHaciricaH from "src/views/Team/images/AvatarHaciricaH";

function renderIcon(param) {
    switch(param) {
        case 'HolyBasilOnly':
            return <AvatarHolyBasilOnly />;
        case 'Vitashoka':
            return <AvatarVitashoka />;
        case 'zxriptor':
            return <Avatarzxriptor />;
        case 'Bindusara':
            return <AvatarBindusara />;
        case 'Chandragupta':
            return <AvatarChandragupta />;
        case 'Thenali Ramen':
            return <AvatarThenaliRamen />;
        case 'Kshatriyan':
            return <AvatarKshatriyan />;
        case 'Dasharatha':
            return <AvatarDasharatha />;
        case 'cg125':
            return <Avatarcg125 />;
        case 'HaciricaH':
            return <AvatarHaciricaH />;
        default:
            return null;
    };
  }

export default class TeamCard extends Component {
    render() {
        return (
            <Card variant="team" sx={{paddingRight: "0", paddingLeft: "0",}}>
                <CardContent sx={{textAlign: "center"}}>
                    {renderIcon(this.props.name)}
                    <Typography variant="h4" sx={{ marginBottom: 1, marginTop: 1, }}>
                        {this.props.name}
                    </Typography>
                    <Typography variant="h5" sx={{ color: 'secondary.main', margin: 1, textAlign: "center" }}>
                        {this.props.description}
                    </Typography>
                    <Typography variant="h5" sx={{ color: 'secondary.main', margin: 1, }}>
                        <Link href={this.props.linkedin} target="_blank" color="inherit" underline="none">
                            <LinkedInIconWhite />
                        </Link>
                    </Typography>
                </CardContent>
            </Card>
        );
    }
}