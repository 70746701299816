import { FC } from "react";
import { Box } from "@mui/material";
import LogoImage from "src/assets/images/panadao-logo.png";

export const Logo: FC = () => {
    return (
        <Box
            component="img"
            sx={{
                maxWidth: 275,
                maxHeight: 45
            }}
            alt="Pana DAO"
            src={LogoImage}
        />
    );
}

export default Logo;