import { FC } from 'react';
import { Container, Box } from '@mui/material';
import StupaImage from 'src/assets/images/Stupa-darker-transparent.png';
import ArtByMona from './ArtByMona';

export const StupaSketchColor: FC = () => {
  return (
    <Container sx={{ position: "relative", paddingBottom: 1, paddingTop: 0, }}>
      <Box data-aos="fade-right" data-aos-duration="1500"
        component="img"
        sx={{
          width: '100%',
          maxWidth: 600,
        }}
        alt="Pana DAO"
        src={StupaImage}
      />
      <ArtByMona />
    </Container>
  );
};

export default StupaSketchColor;
