import { FC } from 'react';
import { Container, Box } from '@mui/material';
import PanaKarshaCoinImage from 'src/assets/images/Karsha-coin-Hindi-font-72ppi.png';
import ArtByMona from './ArtByMona';

export const PanaKarshaCoin: FC = () => {
  return (
    <Container sx={{ display: "flex", position: "relative", minWidth: 250, maxWidth: 500 }}>
      <Box data-aos="flip-right" data-aos-duration="1500"
        component="img"
        sx={{
            width: '100%',
            minWidth: 250, maxWidth: 500
        }}
        alt="Pana DAO"
        src={PanaKarshaCoinImage}
      />
      <ArtByMona />
    </Container>
  );
};

export default PanaKarshaCoin;
