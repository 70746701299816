import { Component } from 'react';
import { Grid, Container, Typography } from "@mui/material";

import NeuralMetricsCapital from 'src/components/Images/NeuralMetricsCapital';

export default class Credits extends Component {
    render() {
        return (
          <Container variant='secondary' maxWidth={false}>
              <Grid container variant="secondary" spacing={0}>
                <Grid item xs={12} md={4} variant="info-secondary">
                  <Grid container columns={1}>
                    <Grid item xs={1}
                    sx={{ 
                      paddingTop: { xs: 1, sm: 0, },
                      display: "flex",
                      width: "100%",
                      justifyContent: "center", }}>
                      <Typography variant='creditsTextBold'>
                        INCUBATED BY
                      </Typography>
                    </Grid>
                    <Grid item xs={1}
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "flex-start",
                      margin: 0,
                      paddingTop: 2,
                      paddingBottom: 2,
                      justifyContent: "center",
                    }}>
                      <NeuralMetricsCapital />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8} variant="info-secondary">
                  <Grid container spacing={0} columns={1}>
                    <Grid item xs={12}
                    sx={{ 
                      paddingTop: { xs: 1, sm: 0, },
                      display: "flex",
                      width: "100%",
                      justifyContent: "center", }}>
                      <Typography variant='creditsTextBold'>
                        SUPPORTED BY
                      </Typography>
                    </Grid>
                    <Grid item xs={12} 
                    sx={{ 
                      paddingTop: { xs: 2, },
                      display: "flex",
                      width: "100%",
                      justifyContent: "center", }}>
                      <Typography variant='creditsTexth5'>
                          Industry Advisors
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} variant="info-secondary"
                 sx={{ 
                  paddingTop: { xs: 1, sm: 1, },
                  display: "flex",
                  width: "100%",
                  textAlign: { xs: "center", sm: "center" },
                  justifyContent: "center", }}>
                  <Typography variant='creditsText'>
                    Leveraging NeuralMetrics Artificial Intelligence and Machine Learning (AIML) Technology
                  </Typography>
                </Grid>
                  </Grid>
                </Grid>
                
              </Grid>
          </Container>
        );
    }
}