import { Component } from 'react';
import Typography from '@mui/material/Typography';
import { Card, CardContent, } from "@mui/material";

import IconEye from 'src/components/Icons/eye';
import IconGap from 'src/components/Icons/gap';
import IconUmbrella from 'src/components/Icons/umbrella';
import IconBadge from 'src/components/Icons/badge';
import IconLight from 'src/components/Icons/light';

function renderIcon(param) {
    switch(param) {
        case 'eye':
            return <IconEye />;
        case 'gap':
            return <IconGap />;
        case 'umbrella':
            return <IconUmbrella />;
        case 'badge':
            return <IconBadge />;
        case 'light':
            return <IconLight />;
        default:
            return null;
    };
  }

export default class InfoCard extends Component {
    render() {
        return (
            <Card variant="info" sx={{paddingRight: "0", paddingLeft: "0"}}>
                <CardContent sx={{textAlign: "center"}}>
                    {renderIcon(this.props.icon)}
                    <Typography variant="h4" sx={{ marginBottom: 1, marginTop: 1, }}>
                        {this.props.title}
                    </Typography>
                    <Typography variant="h5" sx={{ color: 'secondary.main', marginTop: "1.5rem" }}>
                        {this.props.content}
                    </Typography>
                </CardContent>
            </Card>
        );
    }
}