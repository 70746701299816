import { Component } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, Container, Box, Link } from "@mui/material";

import background from "src/assets/images/bg-1.png";

import SectionTeam from "src/components/Section/Team";

import "./team.scss";

const styles = {
  paperContainer: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100%",
  },
};

export default class Team extends Component {
  render() {
    return (
      <main sx={{ margin: 0, padding: 0, innerHeight: '100%' }}>
        <Box
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 2,
            paddingBottom: 15,
          }}
        >
          <SectionTeam />
        </Box>
      </main>
    );
  }
}
