import { Component } from "react";
import { HashRouter } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import { girth as gTheme } from "./themes/girth.js";
import AOS from 'aos';
import "aos/dist/aos.css";


import App from "./App";

export default class Root extends Component {
  
  componentDidMount() {
    // or simply just AOS.init();
    AOS.init({
      // initialise with other settings
      duration : 1000,
      once: true
    });
  }

  render() {
    return (
      <ThemeProvider theme={gTheme}>
        <CssBaseline />
      <HashRouter basename="/">
        <App />
      </HashRouter>
    </ThemeProvider>
    );
  }
}