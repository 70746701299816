import { Component } from "react";
import { Container, Grid, Typography, Box } from "@mui/material";

import background from "../../assets/images/problem-1-30-min.png";

import { insuranceProblems } from "../../views/Landing/landingData";

const styles = {
  slideBox: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#000",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: "600px",
    alignItems: "center",
    margin: 0,
    paddingTop: 1,
    paddingBottom: 1,
  },
  slideHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    
    textShadow: "2px 2px #000",
  },
  slideContentHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    textShadow: "1px 1px #000",
    
  },
  slideContent: {
    display: "inline-block",
    textShadow: "1px 1px #000",
    
  },
  slideContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default class InsuranceProblems extends Component {
  render() {
    return <>{GetInsuranceProblems()}</>;
  }
  z;
}

function GetInsuranceProblems(props) {
  return (
    <Box style={styles.slideBox}>
      <Container
        variant="primary"
        style={styles.slideContainer}
        disableGutters={true}
      >
        <Grid container variant="primary" spacing={0}>
          <Grid
            item
            style={styles.slideHeader}
            xs={12}
            sx={{ alignItems: "center", padding: 1, textAlign: "center", marginBottom: 1 }}
          >
            <Typography variant="h1" sx={{ display: "inline" }}>
              The Top 6
              <Typography
                variant="h1"
                sx={{ display: "inline", color: "primary.contrastText" }}
              >
                &nbsp;Problems
                <Typography
                  variant="h1"
                  sx={{ display: "inline", color: "#FFF" }}
                >
                  &nbsp;with Traditional Insurance
                </Typography>
              </Typography>
            </Typography>
          </Grid>
          {insuranceProblems.map((item, i) => (
            <InsuranceProblem key={i} item={item} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

function InsuranceProblem(props) {
  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: { xs: 1, md: 2 },
        border: "#4A4A4A solid 2px",
        margin: 1,
        marginRight: 4,
        marginLeft: 4,
        borderRadius: 5,
        backgroundColor: "rgba(255,255,255,0.15)",
      }}
    >
      <Typography
        variant="h4"
        style={styles.slideContentHeader}
      >
        {props.item.title}
      </Typography>
      {/* <Typography variant="h6" style={styles.slideContent}>
        {props.item.description}
      </Typography> */}
    </Grid>
  );
}
