import { Component } from "react";
import { Container, Grid, Typography, Box } from "@mui/material";

import background from "../../assets/images/triple-point-asset-30.png";

import {
  assetPoints,
} from "../../views/Landing/landingData";

const styles = {
  secondContainer: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#000",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: "600px",
    alignItems: "center",
    margin: 0,
    paddingTop: 1,
    paddingBottom: 1,
  },
  slideHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    
    textShadow: "2px 2px #000",
  },
  slideContentHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    textShadow: "1px 1px #000",
    color: "primary.contrastText",
    
  },
  slideContentHeaderLeft: {
    display: "flex",
    width: "100%",
    justifyContent: "left",
    alignItems: "left",
    textAlign: "left",
    textShadow: "1px 1px #000",
    color: "primary.contrastText",
    
  },
  slideContent: {
    display: "inline-block",
    textShadow: "1px 1px #000",
    
  },
  slideContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default class LandingCarousel extends Component {
  render() {
    return <>{GetPanaDao()}</>;
  }
  z;
}

function GetPanaDao(props) {
  return (
    <Box style={styles.secondContainer}>
      <Container
        variant="primary"
        style={styles.slideContainer}
        disableGutters={true}
      >
        <Grid container variant="primary" spacing={0}>
          <Grid
            item
            style={styles.slideHeader}
            xs={12}
            sx={{ alignItems: "center", padding: 1, textAlign: "center", marginBottom: 1 }}
          >
            <Typography variant="h2" sx={{ display: "inline" }}>
            A Humble Mission to{" "}
              <Typography
                variant="h2"
                sx={{ display: "inline", color: "primary.contrastText" }}
              >
                &nbsp;Serve the Underserved.
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            style={styles.slideHeader}
            xs={12}
            sx={{ alignItems: "center", padding: 1, textAlign: "center", marginBottom: 1 }}
          >
            <Typography
              variant="h2"
              sx={{ display: "inline", color: "#FFF" }}
            >
              &nbsp;A Bold Goal to Provide{" "}
              <Typography
                variant="h2"
                sx={{ display: "inline", color: "primary.contrastText" }}
              >
                &nbsp;Insurance to Everyone.
              </Typography>
            </Typography>
          </Grid>
    <Grid
      item
      xs={12}
      sx={{
        padding: { xs: 1, md: 2 },
        border: "#4A4A4A solid 2px",
        margin: 1,
        marginRight: 4,
        marginLeft: 4,
        borderRadius: 5,
        backgroundColor: "rgba(255,255,255,0.15)",
      }}
    >
    <Typography
      variant="h3"
      sx={{ paddingLeft: 5, paddingRight: 5, alignItems: "left" }}
      style={styles.slideContentHeader}
    >
      By introducing democratized insurance via DeFi, we are creating the world's first triple-point asset of its kind:
    </Typography>
    <Typography
      variant="h4"
      sx={{ paddingLeft: 5, paddingRight: 5, alignItems: "left" }}
      style={styles.slideContentHeader}>
                  <ol>
    {assetPoints.map((item, i) => (
            <AssetPoint key={i} item={item} />
          ))}
          </ol></Typography>
                <Typography variant="h3"
                  style={styles.slideContentHeader}>
                  We call these unique assets&nbsp;
                <Typography
                  data-aos="zoom-in" data-aos-duration="5500" data-aos-offset="50"
                  variant="h3"
                  sx={{
                    display: "inline",
                    color: "primary.contrastText",
                    textDecoration: "underline",
                  }}
                >
                  Assurance NFTs
                </Typography>
                </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

function AssetPoint(props) {
  return (
      <li key={props.key}>
        <Typography
  variant="h4"
  style={styles.slideContentHeaderLeft}
  sx={{
    paddingLeft: 0,
    alignItems: "left",
    marginTop: "10px"
  }}
>{props.item}</Typography>
      </li>
  );
}
