import { Component } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Route, HashRouter, Link } from "react-router-dom";
import { Grid, Container, Box, Link as MUILink } from "@mui/material";

import background from "src/assets/images/bg-1.png";

import DiscordIconWhite from "src/components/Icons/discord_white";
import MediumIconWhite from "src/components/Icons/medium_white";
import TwitterIconWhite from "src/components/Icons/twitter_white";
import LinkedInIconWhite from "src/components/Icons/linkedin_white";
import LearnMenu from "src/components/Section/LearnMenu";

import Logo from "src/components/Images/Logo";

import { LINK_MEDIUM, LINK_DISCORD, LINK_APP } from 'src/constants';

export default class Landing extends Component {
    render() {
      return (
        <Container variant="primary" maxWidth={false}>
          <div id="home">
            <Grid container variant="primary">
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: { xs: "center", md: "left" },
                }}
              >
                <Logo />
              </Grid>
              <Grid 
                item 
                xs={12} 
                md={9}
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: { xs: "center", md: "right" },
                  paddingTop: { xs: 1.75, md: 0 },
                }}
              >
              <Link to='/' color="inherit" style={{ textDecoration: 'none' }}>
                <Button
                  variant="plain-primary"
                  sx={{ paddingRight: 1.75, paddingLeft: 1.75, paddingTop: 0, paddingBottom: 0 }}
                >
                    <Typography variant="h6">Home</Typography>
                </Button>
              </Link>
              <Link to='/Team' color="inherit" style={{ textDecoration: 'none' }}>
                <Button
                  variant="plain-primary"
                  sx={{ paddingRight: 1.75, paddingLeft: 1.75, paddingTop: 0, paddingBottom: 0 }}
                >
                    <Typography variant="h6">Team</Typography>
                </Button>
              </Link>
              <Link to='/Roadmap' color="inherit" style={{ textDecoration: 'none' }}>
                <Button
                  variant="plain-primary"
                  sx={{ paddingRight: 1.75, paddingLeft: 1.75, paddingTop: 0, paddingBottom: 0 }}
                >
                    <Typography variant="h6">Roadmap</Typography>
                </Button>
              </Link>
              <LearnMenu />
                <MUILink href={LINK_DISCORD} target="_blank" color="inherit" underline="none">
                  <Button
                    variant="plain-primary"
                    sx={{ paddingRight: 1.75, paddingLeft: 1.75, paddingTop: 0, paddingBottom: 0 }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h6">Discord</Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </MUILink>
                {LINK_APP && LINK_APP != '' ? 
                <>
                  <MUILink href={LINK_APP} target="_blank" color="inherit" underline="none">
                    <Button
                      variant="outlined"
                      sx={{ paddingRight: 1.75, paddingLeft: 1.75, paddingTop: 0, paddingBottom: 0.4 }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="h6">Launch&nbsp;App</Typography>
                        </Grid>
                      </Grid>
                    </Button>
                  </MUILink>
                </> : <></>}
              </Grid>
            </Grid>
          </div>
        </Container>
      );
    };
}