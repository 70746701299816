import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from "@mui/material/Typography";
import { Route, HashRouter, Link } from "react-router-dom";
import { Grid, Container, Box, Link as MUILink } from "@mui/material";

import { LINK_MEDIUM, LINK_DISCORD, LINK_APP, LINK_GITBOOK } from 'src/constants';

export default function LearnMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (e) => {
    if (e.currentTarget.localName !== "ul") {
      const menu = document.getElementById("simple-menu").children[2];
      const menuBoundary = {
        left: menu.offsetLeft,
        top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
        right: menu.offsetLeft + menu.offsetWidth,
        bottom: menu.offsetTop + menu.offsetHeight
      };
      if (
        e.clientX >= menuBoundary.left &&
        e.clientX <= menuBoundary.right &&
        e.clientY <= menuBoundary.bottom &&
        e.clientY >= menuBoundary.top
      ) {
        return;
      }
    }

    setOpen(false);
  };

  const handleCloseExplicit = (e) => {
    setOpen(false);
  };

  return (
    <div>
        <Button
            id="menubutton1"
            aria-owns={open ? "simple-menu" : null}
            aria-haspopup="true"
            onMouseOver={handleOpen}
            onMouseLeave={handleClose}
            onClick={handleOpen}
            style={{ zIndex: 1301 }}
            variant="plain-primary"
            sx={{ paddingRight: 1.75, paddingLeft: 1.75, paddingTop: 0, paddingBottom: 0 }}
        >
        <Typography variant="h6">Learn</Typography>
        </Button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={open}
            anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
            }}
            transformOrigin={{
            vertical: "top",
            horizontal: "center"
            }}
            MenuListProps={{
            onMouseLeave: handleClose
            }}
        >
            <div>
            <MUILink href={LINK_MEDIUM} target="_blank" color="inherit" underline="none">
                <Button
                    variant="plain-primary"
                    onClick={handleCloseExplicit}
                    sx={{ paddingRight: 1.75, paddingLeft: 1.75, paddingTop: 0, paddingBottom: 0 }}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ width: 200, textAlign: 'center'}}>Medium</Typography>
                        </Grid>
                    </Grid>
                </Button>
            </MUILink>
            </div>
            <div>
            <Link to='/Memorandum' color="inherit" style={{ textDecoration: 'none' }}>
            <Button
                variant="plain-primary"
                onClick={handleCloseExplicit}
                sx={{ paddingRight: 1.75, paddingLeft: 1.75, paddingTop: 0, paddingBottom: 0 }}
            >
                <Typography variant="h6" sx={{ width: 200, textAlign: 'center'}}>Memorandum</Typography>
            </Button>
            </Link>
            </div>
            <div>
            <MUILink href={LINK_GITBOOK} target="_blank" color="inherit" underline="none">
                <Button
                    variant="plain-primary"
                    onClick={handleCloseExplicit}
                    sx={{ paddingRight: 1.75, paddingLeft: 1.75, paddingTop: 0, paddingBottom: 0 }}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ width: 200, textAlign: 'center'}}>Documentation</Typography>
                        </Grid>
                    </Grid>
                </Button>
            </MUILink>
            </div>
        </Menu>
    </div>
  );
};