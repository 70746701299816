import { FC } from "react";
import { Box } from "@mui/material";
import NeuralmetricsCapitalImage from 'src/assets/images/neuralmetrics_capital_logo.svg';

export const NeuralmetricsCapital: FC = () => {
  return (
    <Box
      component="img"
      sx={{
          maxWidth: 225,
          paddingLeft: 0,
      }}
      alt="NeuralMetrics Capital"
      src={NeuralmetricsCapitalImage}
    />
  );
};

export default NeuralmetricsCapital;
