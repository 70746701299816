import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { handleBackdropFilter } from "./global.js";

const breakpointValues = {
  xs: 0,
  sm: 596,
  md: 800,
  lg: 1000,
  xl: 1333,
};

export const girthTheme = {
  colorPrimary: "#FFF",
  colorSecondary: "#898989",
  colorTertiary: "#EC9E24",
  colorSecondaryTertiary: "#000",
  largeTextColor: "#838283",
  activeLinkColor: "#222222",

  maxContentWidth: 1920,

  gold: "#F8CC82",
  textHighlightColor: "#F4D092",

  backgroundColorPaper: "#171717",
  backgroundColorPrimary: "#000",
  backgroundColorSecondary: "#EDE7D8",
  backgroundColorTertiary: "#101010",

  infoCardBorder: "#4A4A4A solid 1px",
  teamCardBorder: "#F4D092 dashed 4px",
  
  paperBg: "#424242",
  menuBg: handleBackdropFilter("rgba(66, 66, 66, 0.41)"),
  modalBg: "#F9F9F9AA",
  primaryButtonBG: "#F8CC82",
  primaryButtonHoverBG: "#759AAE",
  secondaryButtonBG: "linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)",
  secondaryButtonHoverBG: "rgba(54, 56, 64, 1)",
  outlinedPrimaryButtonHoverBG: "#F8CC82",
  outlinedPrimaryButtonHoverColor: "#333333",
  outlinedSecondaryButtonHoverBG: "#FCFCFC",
  outlinedSecondaryButtonHoverColor: "#333333",
  containedSecondaryButtonHoverBG: "#363840",


  fontSize_h1: "3rem",
  fontSize_h1_mobile: "1.75rem",
  fontSize_h2: "2rem",
  fontSize_h2_mobile: "1.25rem",
  fontSize_h3: "1.75rem",
  fontSize_h3_mobile: "1.25rem",
  fontSize_h4: "1.5rem",
  fontSize_h4_mobile: "1rem",
  fontSize_h5: "1.25rem",
  fontSize_h5_mobile: "0.875rem",
  fontSize_h6: "1rem",
  fontSize_h6_mobile: "0.6rem",
  body1: "0.875rem",
  body1_mobile: "0.70rem",
  body2: "0.75rem",
  body2_mobile: "0.60rem",
  button: "1.25rem",
  button_mobile: "0.875rem",
};

export const girth = responsiveFontSizes(
  createTheme({
    palette: {
      type: 'light',
      primary: {
        main: girthTheme.colorPrimary,
        contrastText: girthTheme.colorTertiary,
        light: girthTheme.backgroundColorTertiary,
      },
      secondary: {
        main: girthTheme.colorSecondary,
        contrastText: girthTheme.colorSecondaryTertiary,
      },
      background: {
        default: girthTheme.backgroundColorSecondary,
        paper: girthTheme.backgroundColorPrimary,
      },
    },
    components: {
      MuiCssBaseline: {
        "@global": {
          breakpoints: { values: breakpointValues },
          body: {
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          },
        },
      },
      MuiContainer: {
        variants: [
          {
            props: { variant: 'primary' },
            style: {
              flexGrow: 1,
              maxWidth: girthTheme.maxContentWidth,
            },
          },
          {
            props: { variant: 'secondary' },
            style: {
              flexGrow: 1,
              maxWidth: girthTheme.maxContentWidth,
            },
          },
          {
            props: { variant: 'tertiary' },
            style: {
              flexGrow: 1,
              maxWidth: girthTheme.maxContentWidth,
            },
          },
          {
            props: { variant: 'creditsText' },
            style: {
              fontSize: girthTheme.fontSize_h6,
              paddingTop: 5,
              color: girthTheme.colorSecondaryTertiary,
            },
          },
          {
            props: { variant: 'creditsTextBold' },
            style: {
              fontSize: girthTheme.fontSize_h6,
              paddingTop: 5,
              fontWeight: 700,
              color: girthTheme.colorSecondaryTertiary,
            },
          },
          {
            props: { variant: 'creditsTextLarge' },
            style: {
              fontSize: girthTheme.fontSize_h1,
              paddingTop: 5,
              color: girthTheme.colorSecondaryTertiary,
            },
          },
        ]
      },
      MuiGrid: {
        variants: [
          {
            props: { variant: 'primary' },
            style: {
              color: girthTheme.colorPrimary,
              height: '100%',
              textTransform: 'none',
              width: '100%',
              maxWidth: girthTheme.maxContentWidth,
            },
          },
          {
            props: { variant: 'secondary'},
            style: {
              color: girthTheme.colorSecondary,
              height: '100%',
              textTransform: 'none',
              maxWidth: girthTheme.maxContentWidth,
            },
          },
          {
            props: { variant: 'secondary-background'},
            style: {
              color: girthTheme.colorPrimary,
              height: '100%',
              textTransform: 'none',
              maxWidth: girthTheme.maxContentWidth,
            },
          },
          {
            props: { variant: 'info-primary'},
            style: {
              fontSize: 12,
              color: girthTheme.colorPrimary,
              padding: 10,
              textTransform: 'none',
              backgroundColor: girthTheme.backgroundColorPrimary,
            },
          },
          {
            props: { variant: 'info-secondary'},
            style: {
              fontSize: 12,
              color: girthTheme.colorSecondary,
              backgroundColor: girthTheme.backgroundColorSecondary,
            },
          },
          {
            props: { variant: 'footer'},
            style: {
              color: girthTheme.colorSecondary,
              padding: 10,
              textTransform: 'none',
            },
          },
        ],
      },
      MuiCard: {
        variants: [
          {
            props: { variant: 'info' },
            style: {
              textTransform: 'none',
              color: girthTheme.colorPrimary,
              margin: 15,
              padding: 25,
              minWidth: '200px',
              height: '98%',
              borderRadius: 20,
              border: girthTheme.infoCardBorder,
              backgroundColor: girthTheme.backgroundColorPrimary,
            },
          },
          {
            props: { variant: 'team' },
            style: {
              textTransform: 'none',
              color: girthTheme.colorPrimary,
              margin: 15,
              padding: 5,
              width: '300px',
              height: '400px',
              borderRadius: 20,
              border: girthTheme.teamCardBorder,
              backgroundColor: girthTheme.backgroundColorPrimary,
            },
          },
        ],
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'outlined-primary' },
            style: {
              textTransform: 'none',
              color: girthTheme.colorPrimary,
              fontSize: '20px',
              margin: 0,
              padding: 12,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: girthTheme.backgroundColorSecondary,
            },
          },
          {
            props: { variant: 'outlined-secondary' },
            style: {
              textTransform: 'none',
              color: girthTheme.colorPrimary,
              backgroundColor: girthTheme.backgroundColorSecondary,
              fontSize: '20px',
              margin: 0,
              padding: 12,
              borderWidth: 3,
              borderStyle: 'solid',
              borderColor: girthTheme.backgroundColorSecondary,
            },
          },
          {
            props: { variant: 'outlined-tertiary' },
            style: {
              textTransform: 'none',
              color: girthTheme.colorPrimary,
              backgroundColor: girthTheme.backgroundColorPrimary,
              fontSize: '20px',
              margin: 0,
              padding: 12,
              borderWidth: 5,
              borderStyle: 'solid',
              borderColor: girthTheme.colorTertiary,
            },
          },
          {
            props: { variant: 'plain-primary' },
            style: {
              textTransform: 'none',
              color: girthTheme.colorPrimary,
            },
          },
          {
            props: { variant: 'plain-secondary' },
            style: {
              textTransform: 'none',
              color: girthTheme.colorSecondary,
            },
          },
        ],
      },
      MuiTypography: {
        variants: [
          {
            props: { variant: 'creditsText' },
            style: {
              fontSize: girthTheme.fontSize_h6,
              color: girthTheme.colorSecondaryTertiary,
            },
          },
          {
            props: { variant: 'creditsTextBold' },
            style: {
              fontSize: girthTheme.fontSize_h6,
              fontWeight: 700,
              color: girthTheme.colorSecondaryTertiary,
            },
          },
          {
            props: { variant: 'missionTextLarge' },
            style: {
              fontSize: girthTheme.fontSize_h1,
              fontWeight: 700,
              color: girthTheme.colorPrimary,
            },
          },
          {
            props: { variant: 'creditsTexth5' },
            style: {
              fontSize: girthTheme.fontSize_h5,
              fontWeight: 700,
              color: girthTheme.colorSecondaryTertiary,
            },
          },
        ]
      },
    },
  direction: "ltr",
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: girthTheme.fontSize_h1,
      fontWeight: 600,
      letterSpacing: "1.3px",
    },
    h2: {
      fontSize: girthTheme.fontSize_h2,
      fontWeight: 600,
      letterSpacing: "1.3px",
    },
    h3: {
      fontSize: girthTheme.fontSize_h3,
      '@media (max-width:1024px)': {
        fontSize: girthTheme.fontSize_h3_mobile,
      },
    },
    h4: {
      fontSize: girthTheme.fontSize_h4,
      '@media (max-width:1024px)': {
        fontSize: girthTheme.fontSize_h4_mobile,
      },
    },
    h5: {
      fontSize: girthTheme.fontSize_h5,
      '@media (max-width:1024px)': {
        fontSize: girthTheme.fontSize_h5_mobile,
      },
      letterSpacing: "0.4px",
    },
    h6: {
      fontSize: girthTheme.fontSize_h6,
      '@media (max-width:1024px)': {
        fontSize: girthTheme.fontSize_h6_mobile,
      },
    },
    body1: {
      fontSize: girthTheme.body1,
      '@media (max-width:1024px)': {
        fontSize: girthTheme.body1_mobile,
      },
      fontWeight: 500,
      lineHeight: 1,
    },
    body2: {
      fontSize: girthTheme.body2,
      '@media (max-width:1024px)': {
        fontSize: girthTheme.body2_mobile,
      },
      fontWeight: 400,
      lineHeight: 1,
    },
    button: {
      textTransform: "none",
      fontSize: girthTheme.button,
      '@media (max-width:1024px)': {
        fontSize: girthTheme.button_mobile,
      },
    },
  },
    props: {
      MuiButton: {
        size: 'small',
      },
      MuiButtonGroup: {
        size: 'small',
      },
      MuiCheckbox: {
        size: 'small',
      },
      MuiFab: {
        size: 'small',
      },
      MuiFormControl: {
        margin: 'dense',
        size: 'small',
      },
      MuiFormHelperText: {
        margin: 'dense',
      },
      MuiIconButton: {
        size: 'small',
      },
      MuiInputBase: {
        margin: 'dense',
      },
      MuiInputLabel: {
        margin: 'dense',
      },
      MuiRadio: {
        size: 'small',
      },
      MuiSwitch: {
        size: 'small',
      },
      MuiTextField: {
        margin: 'dense',
        size: 'small',
      },
    },
  }
  ),
);


