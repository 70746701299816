import { Component } from 'react';
import Typography from '@mui/material/Typography';
import { Grid, Container, } from "@mui/material";
import TeamCard from 'src/components/TeamCard/TeamCard';

import { teamData } from "../../views/Team/teamData";

export default class Team extends Component {
    render() {
        return (
            <div id="team">
            <Container variant='secondary' maxWidth={false} disableGutters={true}>
                <Grid container variant="secondary-background">
                  <Grid item xs={12} sx={{ paddingBottom: 3, paddingLeft: 3, textAlign: "center"}}>
                    <Grid container sx={{ display: "contents" }}>
                      <Typography variant="h2" sx={{ display: 'inline', }}>The Pana DAO&nbsp;</Typography>
                      <Typography variant="h2" sx={{ display: 'inline', color: 'primary.contrastText', }}>Dev Team</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} sx={{ textAlign: "center"}}>
                  </Grid>
                  <Grid item xs={8} sx={{ padding: 3, textAlign: "justify"}}>
                      <Typography variant="h5" sx={{ display: 'inline', }}>
                      In our Web2 lives, we solved some of the hardest problems in insurance - by creating solutions that provide insurance to the underserved in the traditional world. We believe everyone deserves equitable access to a bright future. Learn more about us:
                      </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{ textAlign: "center"}}>
                  </Grid>
                  <Grid item xs={1} sx={{ textAlign: "center"}}>
                  </Grid>
                  <Grid item xs={10} sx={{ textAlign: "center"}}>
                    <Grid container
                        alignItems="center"
                        justifyContent="center">
                    { teamData.map((data, index) => (
                        <Grid data-aos="zoom-in" data-aos-duration="1500" item key={index}>
                            <TeamCard avatar={data.avatar} name={data.name} description={data.description} linkedin={data.linkedin} />
                        </Grid>
                    ))}
                    </Grid>
                    </Grid>
                    <Grid item xs={1} sx={{ textAlign: "center"}}>
                    </Grid>
                </Grid>
            </Container>
            </div>
        );
    }z
}