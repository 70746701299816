import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import { Landing, Memorandum, Roadmap, Team } from "./views";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
//import "./style.scss";

import SectionHeader from "src/components/Section/Header";
import SectionFooter from "src/components/Section/Footer";

//import { dark as darkTheme } from "./themes/dark.js";
import { girth as gTheme } from "./themes/girth.js";

export default function App() {

  return (
    <ThemeProvider theme={gTheme}>
      <CssBaseline /> 
      {/* Hero unit */}
      <div className={`app girth`}>
      <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
          }}
        >
        <Box
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
        <SectionHeader />
        </Box>
      <Routes>
        <Route path={"/"} element={<Landing />} />
        <Route path={"/Memorandum"} element={<Memorandum />} />
        <Route path={"/Roadmap"} element={<Roadmap />} />
        <Route path={"/Team"} element={<Team />} />
      </Routes>
        <Box
          sx={{
            backgroundColor: "primary.light",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 3,
            paddingBottom: 3,
            flex: 1,
          }}
        >
          <SectionFooter />
        </Box>
      </Box>
      </div>
    </ThemeProvider>
  );
}