import { Component } from 'react';
import Typography from '@mui/material/Typography';
import { Grid, Container, } from "@mui/material";

import StupaSketchColor from 'src/components/Images/StupaSketchColor';

export default class Insurance extends Component {
    render() {
        return (
          <Container variant='primary' maxWidth={false}>
              <Grid container variant="primary" spacing={0}>
                <Grid item xs={12} md={5} style={{alignItems: "center", display: "flex" }}  >
                    <StupaSketchColor />
                </Grid>
                <Grid data-aos="fade-left" data-aos-duration="2000" item xs={12} md={7}>
                    <Typography variant="h2" sx={{ display: 'inline-block', paddingBottom: 2, }}>Sustainable</Typography>
                    <Typography variant="h5" sx={{ display: 'inline-block', paddingBottom: 5, color: 'secondary.main', }}>
                    The Karsha token and Assurance NFTs are designed for the long-term, including future efforts to bring them off-chain to the least privileged. All assets are backed by a reserve from the Pana DAO master treasury with tokenomics and treasury disaggregation balanced to protect investments and grow value over time. The entire effort is stewarded through the power of blockchain transparency, code and the Pana DAO community.
                    </Typography>
                    <Typography variant="h2" sx={{ display: 'inline-block', paddingBottom: 2, }}>A Digital Cooperative</Typography>
                    <Typography variant="h5" sx={{ display: 'inline-block', color: 'secondary.main', }}>
                    The Pana economy is stewarded by a global community who are members of the Pana DAO. A DAO is a decentralized autonomous organization - ours is focused on democratizing insurance for everyone. Come join us on Discord.
                    </Typography>
                </Grid>
              </Grid>
          </Container>
        );
    }
}