import React from 'react';
import { SvgIcon } from "@mui/material";
import { ReactComponent as Logo } from "../../assets/icons/icon_clyde_white_CMYK_25.svg";

const DiscordIcon = () => (
  <SvgIcon sx={{ width: 33, height: 25, margin: 1, marginRight: 1, }}>
    <Logo />
  </SvgIcon>
);

export default DiscordIcon;