import { Component } from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Button, Container, Grid, Typography, Box } from "@mui/material";

import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import background from "../../assets/images/blockchain-lock-1-30-min.png";

const styles = {
  slideMaster: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#000",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: "600px",
    alignItems: "center",
    margin: 0,
    paddingTop: 1,
    paddingBottom: 1,
  },
  slideHeader: {
    width: "100%",
    justifyContent: "center",
    
    textShadow: "2px 2px #000",
  },
  slideContentHeader: {
    display: "inline",
    textShadow: "1px 1px #000",
    color: "primary.contrastText",
    
  },
  slideContent: {
    display: "inline-block",
    textShadow: "1px 1px #000",
    
  },
  slideContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default class HowPanaWorks_2 extends Component {
  render() {
    return (
      <Box style={styles.slideMaster}>
        <Grid
          container
          variant="primary"
          spacing={0}
          sx={{ width: "100%", height: "100%" }}
        >
          <Grid
            item
            style={styles.slideHeader}
            xs={12}
            sx={{
              alignItems: "center",
              padding: 1,
              textAlign: "center",
            }}
          >
            <Typography variant="h1" sx={{ display: "inline" }}>
              How Pana
              <Typography
                variant="h1"
                sx={{ display: "inline", color: "primary.contrastText" }}
              >
                &nbsp;Works
              </Typography>
              <Typography variant="h2" sx={{ color: "#FFF" }}>
                Step #3
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}
          sx={{
            margin: 1,}}>
            <Container
              variant="primary"
              style={styles.slideContainer}
              disableGutters={true}
            >
              <Grid
                container
                variant="primary"
                sx={{
                  width: "100%",
                  display: "flex",
                  backgroundColor: "rgba(255,255,255,0.25)",
                  justifyContent: "center",
                  borderRadius: 5,
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    height: 470,
                  }}
                >
                  {GetHowPanaWorks_Labels()}
                </Grid>
                <Grid
                  item
                  xs={9}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    height: 470,
                  }}
                >
                  {GetHowPanaWorks_Treasury()}
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

function GetHowPanaWorks_Treasury(props) {
  return (
    <Grid
      container
      variant="primary"
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: 470,
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          height: 175,
        }}
      >
        <Typography variant="h5" style={styles.slideHeader}>
          Treasury Ownership Increases as Participants <br></br>
          <CircleRoundedIcon
            sx={{ transform: "scale(.9)", paddingTop: 1.5 }}
          />{" "}
          Make Claims<br></br>
          <CircleRoundedIcon
            sx={{ transform: "scale(.9)", paddingTop: 1.5 }}
          />{" "}
          Don't Repay Loans<br></br>
          <CircleRoundedIcon
            sx={{ transform: "scale(.9)", paddingTop: 1.5 }}
          />{" "}
          Stop Paying Premiums<br></br>
          <CircleRoundedIcon
            sx={{ transform: "scale(.9)", paddingTop: 1.5 }}
          />{" "}
          Burn their NFT(s) to Cash-Out Early
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          variant="primary"
          sx={{
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(255,255,255,0.25)",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
            height: 295,
            borderTop: "6px solid #fff",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              borderBottom: "6px dotted #fff",
              borderTop: "6px dotted #fff",
              marginTop: 8,
            }}
          >
            <Typography variant="h5" style={styles.slideHeader}>
              Premiums Paid
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              height: 200,
            }}
          >
            <Typography variant="h5" style={styles.slideHeader}>
              Claim Value = % of Treasury Owned
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function GetHowPanaWorks_Labels(props) {
  return (
    <Grid
      container
      variant="primary"
      sx={{ width: "100%", display: "flex",
      borderRight: "6px solid #fff", }}
    >
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        width: "100%",
        borderBottom: "6px solid #fff",
        height: 180,
      }}
    >
      <Typography
        variant="h4"
        style={styles.slideContent}
        sx={{ transform: "rotate(-90deg)", letterSpacing: 1 }}
      >
        Increase<br></br>Due to<br></br>Other<br></br>Factors
      </Typography>
    </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          height: 300,
        }}
      >
        <Typography
          variant="h4"
          display="block"
          style={styles.slideContent}
          sx={{ transform: "rotate(-90deg)", letterSpacing: 1 }}
        >
          Increase<br></br> with<br></br> Yield Generation
        </Typography>
      </Grid>
      </Grid>
  );
}
