import React from 'react';
import { SvgIcon } from "@mui/material";
import { ReactComponent as Logo } from "../../assets/icons/twitter_white.svg";

const TwitterIcon = () => (
  <SvgIcon sx={{ width: 50, height: 25, margin: 1, marginRight: 1, }}>
    <Logo />
  </SvgIcon>
);

export default TwitterIcon;