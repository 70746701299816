import { FC } from "react";
import { Container, Box } from "@mui/material";
import LionPillarImage from "src/assets/images/LionPillar.png";
import ArtByMona from "./ArtByMona";

export const LionPillar: FC = () => {
  return (
    <Container sx={{ display: "flex", position: "relative", minWidth: 225, maxWidth: 350 }}>
      <Box
        component="img"
        sx={{
            width: '100%',
            minWidth: 225, maxWidth: 350
        }}
        alt="Pana DAO"
        src={LionPillarImage}
      />
      <ArtByMona />
    </Container>
  );
};

export default LionPillar;
