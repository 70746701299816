import { Component } from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Button, Container, Grid, Typography, Box } from "@mui/material";
import InsuranceProblems from "./Carousel-InsuranceProblems";
import InsuranceSolutions from "./Carousel-InsuranceSolutions";
import ExchangeSelfInsure from "./Carousel-ExchangeSelfInsure";

import background from "../../assets/images/blockchain-1-30-min.png";

const styles = {
  slideMaster: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#000",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: "600px",
    alignItems: "center",
    margin: 0,
    paddingTop: 1,
    paddingBottom: 1,
  },
  slideHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    
    textShadow: "2px 2px #000",
  },
  slideContentHeader: {
    display: "inline",
    textShadow: "1px 1px #000",
    color: "primary.contrastText",
    
  },
  slideContent: {
    display: "inline-block",
    textShadow: "1px 1px #000",
    
  },
  slideContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default class HowPanaWorks_1 extends Component {
  render() {
    return (
      <Box style={styles.slideMaster}>
        <Grid
          container
          variant="primary"
          spacing={0}
          sx={{ width: "100%", height: "100%" }}
        >
          <Grid
            item
            style={styles.slideHeader}
            xs={12}
            sx={{
              alignItems: "center",
              paddingTop: 1,
              textAlign: "center",
            }}
          >
            <Typography variant="h1" sx={{ display: "inline" }}>
              How Pana
              <Typography
                variant="h1"
                sx={{ display: "inline", color: "primary.contrastText" }}
              >
                &nbsp;Works
              </Typography>
              <Typography variant="h2" sx={{ color: "#FFF" }}>
                Step #1
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {GetHowPanaWorks()}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

function GetHowPanaWorks(props) {
  return (
    <Container
      variant="primary"
      style={styles.slideContainer}
      disableGutters={true}
    >
      <Grid
        container
        variant="primary"
        spacing={0}
        sx={{ width: "100%", display: "flex", flexDirection: "row" }}
      >
        <Grid
          item
          xs={3}
          sx={{ alignItems: "center", paddingLeft: 1, textAlign: "center" }}
        ></Grid>

        <Grid
          item
          xs={6}
          sx={{ alignItems: "center", paddingLeft: 1, textAlign: "center" }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              padding: 1,
              textAlign: "center",
              backgroundColor: "rgba(255,255,255,0.25)",
              height: 150,
              justifyContent: "center",
              borderRadius: 5,
              margin: 1,
            }}
          >
            <Typography variant="h3" style={styles.slideContent}>
              Discounted Tokens Received with Each Premium
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={3}
          sx={{ alignItems: "center", paddingLeft: 1, textAlign: "center" }}
        ></Grid>

        <Grid
          item
          xs={12}
          sx={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            borderBottom: "6px dotted #fff",
            borderTop: "6px dotted #fff",
          }}
        >
          <Grid
            container
            variant="primary"
            spacing={0}
            sx={{ width: "100%", display: "flex", flexDirection: "row" }}
          >
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h3"
                style={styles.slideHeader}
                sx={{
                  letterSpacing: 2,
                }}
              >
                &#8679;&#8679;&#8679;
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
            <Box sx={{ transform: "rotate(-180deg)" }}>
            <Typography
              variant="h3"
              style={styles.slideHeader}
              sx={{
                letterSpacing: 2,
              }}
            >
            &#8679;&#8679;&#8679;
            </Typography>
            </Box>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h3"
                style={styles.slideHeader}
                sx={{
                  letterSpacing: 2,
                }}
              >
                &#8679;&#8679;&#8679;
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        variant="primary"
        wrap="nowrap"
        sx={{ width: "100%", display: "flex", height: "100%" }}
      >
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "rgba(255,255,255,0.25)",
            height: 250,
            justifyContent: "center",
            borderRadius: 5,
            margin: 1,
            padding: 1,
          }}
        >
          <Typography variant="h3" style={styles.slideContent}>
            Premiums Paid
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "rgba(255,255,255,0.25)",
            height: 250,
            justifyContent: "center",
            borderRadius: 5,
            margin: 1,
            padding: 1,
          }}
        >
          <Typography variant="h3" style={styles.slideContent}>
            Pana Token Value Received
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "rgba(255,255,255,0.25)",
            height: 250,
            justifyContent: "center",
            borderRadius: 5,
            margin: 1,
            padding: 1,
          }}
        >
          <Typography variant="h3" style={styles.slideContent}>
            100% Premium Used to Generate Claim Value
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
