import { FC } from "react";
import { Box } from "@mui/material";
import AvatarImage from "src/assets/images/HaciricaH.png";

export const Avatar: FC = () => {
    return (
        <Box
            component="img"
            sx={{
                maxWidth: 100,
                maxHeight: 100
            }}
            alt="HaciricaH"
            src={AvatarImage}
        />
    );
}

export default Avatar;