import { Component } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, Container, Box, Link } from "@mui/material";

import background from "src/assets/images/bg-1.png";

import DiscordIconWhite from "src/components/Icons/discord_white";
import MediumIconWhite from "src/components/Icons/medium_white";
import TwitterIconWhite from "src/components/Icons/twitter_white";
import LinkedInIconWhite from "src/components/Icons/linkedin_white";

import Logo from "src/components/Images/Logo";

import { LINK_TWITTER, LINK_LINKEDIN } from 'src/constants';

import SectionMission from "src/components/Section/Mission";
import SectionStory from "src/components/Section/Story";
import SectionCredits from "src/components/Section/Credits";
import SectionInsurance1 from "src/components/Section/Insurance-1";
import SectionInsurance2 from "src/components/Section/Insurance-2";
import SectionLandingCarousel from "src/components/Section/LandingCarousel";
import "./landing.scss";

const styles = {
  paperContainer: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100%",
  },
};

export default class Landing extends Component {
  render() {
    return (
      <main sx={{ margin: 0, padding: 0 }}>
        <Box
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SectionLandingCarousel />
        </Box>
        <Box
          sx={{
            backgroundColor: "background.default",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: { xs:2, sm:4, },
            paddingBottom: 2,
          }}
        >
          <SectionCredits />
        </Box>
        <Box
          style={styles.paperContainer}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 3,
            paddingBottom: 6,
          }}
        >
          <SectionStory />
        </Box>
        <Box
          sx={{
            backgroundColor: "primary.light",
            display: "flex",
            alignItems: "center",
            paddingTop: 3,
            paddingBottom: { xs:4, md:2, },
          }}
        >
          <SectionInsurance1 />
        </Box>
        <Box
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 3,
            paddingBottom: { xs:4, md:2, },
          }}
        >
          <SectionInsurance2 />
        </Box>
      </main>
    );
  }
}
