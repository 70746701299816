import { Component } from "react";
import Typography from "@mui/material/Typography";
import { Grid, Container, Button, Link as MUILink } from "@mui/material";

import LionPillar from "src/components/Images/LionPillar";

import { assetPoints } from "../../views/Landing/landingData";
import DiscordIcon from "src/components/Icons/discord_white_large";

import { LINK_DISCORD } from 'src/constants';

export default class Mission extends Component {
  render() {
    return (
      <Container variant="primary" maxWidth={false}>
        <Grid
          container
          variant="primary"
          sx={{
            paddingBottom: 0,
            paddingTop: 2,
          }}
        >
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid data-aos="zoom-in" data-aos-duration="1000" item xs={12}>
                <Grid container sx={{ display: "inline-block" }}>
                  <Typography variant="h1" sx={{ display: "inline" }}>
                    A Humble Mission to{" "}
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{ display: "inline", color: "primary.contrastText" }}
                  >
                    Serve the Underserved.
                  </Typography>
                </Grid>
                <Grid container sx={{ display: "inline-block" }}>
                  <Typography variant="h1" sx={{ display: "inline" }}>
                    A Bold Goal to Provide{" "}
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{ display: "inline", color: "primary.contrastText" }}
                    
                  >
                    Insurance to Everyone.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={10}
                sx={{
                  display: "inline-block",
                  paddingRight: { xs: 0, sm: 10 },
                  paddingBottom: { xs: 2, sm: 4 },
                }}
              >
                <Typography
                  data-aos="zoom-in" data-aos-duration="1500"
                  variant="h4"
                  sx={{ paddingTop: 4, alignItems: "left" }}
                >
                  By introducing democratized insurance via DeFi, we are
                  creating the world's first triple-point asset of its kind:
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    paddingLeft: 0,
                    alignItems: "left",
                    color: "secondary.main"
                  }}
                >
                  <ol>
                    {assetPoints.map((item, i) => (
                      <li key={item}>
                        <Typography
                  variant="h5"
                  data-aos="fade-right" data-aos-duration={((i + 1) * 500) + 4000}
                  sx={{
                    paddingLeft: 0,
                    alignItems: "left",
                    color: "secondary.main",
                    marginTop: "10px"
                  }}
                >{item}</Typography>
                      </li>
                    ))}
                  </ol>
                </Typography>
              </Grid>
              
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
            }}
          >
            <LionPillar />
          </Grid>
          <Grid
                item
                xs={12}
                sx={{
                  paddingBottom: { xs: 2, sm: 4 },
                  paddingTop: 0,
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <Typography variant="h3" sx={{ display: "inline" }}>
                  We call these unique assets&nbsp;
                </Typography>
                <Typography
                  data-aos="zoom-in" data-aos-duration="5500" data-aos-offset="50"
                  variant="h3"
                  sx={{
                    display: "inline",
                    color: "primary.contrastText",
                    textDecoration: "underline",
                  }}
                >
                  Assurance NFTs
                </Typography>
              </Grid>
          <Grid item xs={12}
            sx={{
              paddingBottom: 2,
              paddingTop: 0,
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}>
            <MUILink href={LINK_DISCORD} target="_blank" color="inherit" underline="none">
              <Button
                data-aos="zoom-in" data-aos-duration="1000" 
                variant="outlined-tertiary"
                sx={{ paddingRight: 1.75, paddingLeft: 1.75, paddingTop: 0, paddingBottom: 0, borderRadius: 5 }}
              >
                <Grid item xs={12} variant="info-primary">
                  <Grid container columns={1}>
                    <Grid item xs={1}
                    sx={{ 
                      paddingTop: { xs: 1, sm: 0, },
                      display: "flex",
                      width: "100%",
                      justifyContent: "center", }}>
                    <DiscordIcon />
                      <Typography variant='missionTextLarge'>
                        &nbsp;Join us on Discord&nbsp;
                      </Typography>
                    <DiscordIcon />
                  </Grid>
                  </Grid>
                </Grid>
              </Button>
            </MUILink>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
