import { Component } from 'react';
import Typography from '@mui/material/Typography';
import { Grid, Container, } from "@mui/material";

import PanaKarshaCoin from 'src/components/Images/PanaKarshaCoin';

export default class Insurance extends Component {
    render() {
        return (
          <Container variant='primary' maxWidth={false}>
              <Grid container variant="primary" spacing={0}>
                <Grid data-aos="fade-right" data-aos-duration="2000" item xs={12} md={7} sx={{ alignItems: "center" }}>
                    <Typography variant="h2" sx={{ display: 'inline', }}>Add parametric insurance with </Typography>
                    <Typography variant="h2" sx={{ display: 'inline', color: 'primary.contrastText', }}>auto-compounding</Typography>
                    <Typography variant="h2" sx={{ display: 'inline', }}> APY to your NFT portfolio and overall DeFi strategy.</Typography>
                    <Typography variant="h5" sx={{ display: 'inline-block', paddingTop: 5, color: 'secondary.main', }}>
                    The Pana economy uses the Karsha token to accrue rewards. Through bonding, Pana DAO develops a treasury to extend democratized insurance across the globe on-chain/off-chain.
                    </Typography>
                    <Typography variant="h5" sx={{ display: 'inline-block', paddingTop: 5, paddingBottom: 3, color: 'secondary.main', }}>
                    All principal paid into an NFT is used to manage supply of the Pana and Karsha tokens, expanding multi-point parametric payout scenarios and growing the treasury.  Using crypto enables asset ownership for everyone while also providing NFTs with parametric valuations when disasters strike in underserved regions around the world.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5} style={{textAlign: "center", }}  >
                    <PanaKarshaCoin />
                </Grid>
              </Grid>
          </Container>
        );
    }
}