import { FC } from 'react';
import { Container, Box } from '@mui/material';
import Roadmap1 from 'src/assets/images/roadmap-1.png';
import ArtByMona from './ArtByMona';

export const PanaKarshaCoin: FC = () => {
  return (
    <Box sx={{ display: "inline-flex", flexShrink: 0, position: "relative", minWidth: 75, maxWidth: 200 }}>
      <Box
        component="img"
        sx={{
          minWidth: 75, maxWidth: 200
        }}
        alt="Pana DAO"
        src={Roadmap1}
      />
      <ArtByMona />
    </Box>
  );
};

export default PanaKarshaCoin;
