import { Component } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, Container, Box, Link } from "@mui/material";

import background from "src/assets/images/bg-1.png";

import DiscordIconWhite from "src/components/Icons/discord_white";
import MediumIconWhite from "src/components/Icons/medium_white";
import TwitterIconWhite from "src/components/Icons/twitter_white";
import LinkedInIconWhite from "src/components/Icons/linkedin_white";
import DocsIcon from "src/components/Icons/docs_white";
import GithubIcon from "src/components/Icons/github_white";

import Logo from "src/components/Images/Logo";

import { LINK_TWITTER, LINK_LINKEDIN, LINK_MEDIUM, LINK_DISCORD, LINK_GITBOOK, LINK_GITHUB } from 'src/constants';

export default class Landing extends Component {
    render() {
      return (
        <Container variant="footer" maxWidth={false}>
          <Grid container variant="footer">
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: { xs: "center", md: "left" },
              }}
            >
              <Logo />
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <Container
                variant="creditsText"
                sx={{ width: "100%", color: "secondary.main", paddingTop: 2, paddingBottom: 2, }}
              >
                A Humble Mission to Serve the Underserved. A Bold Goal to
                Provide Insurance to Everyone.
              </Container>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: { xs: "center", md: "right" },
              }}
            >
            <Grid container>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: { xs: "right" },
              }}
            >
              <Link color="inherit" href={LINK_MEDIUM} underline="none" target="_blank">
                <MediumIconWhite />
              </Link>
              </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: { xs: "center" },
              }}
            >
              <Link color="inherit" href={LINK_TWITTER} underline="none" target="_blank">
                <TwitterIconWhite />
              </Link>
              </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: { xs: "left" },
              }}
            >
              <Link color="inherit" href={LINK_LINKEDIN} underline="none" target="_blank">
                <LinkedInIconWhite />
              </Link>
              </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: { xs: "right" },
              }}
            >
              <Link color="inherit" href={LINK_DISCORD} underline="none" target="_blank">
                <DiscordIconWhite />
              </Link>
              </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: { xs: "center" },
              }}
            >
              <Link color="inherit" href={LINK_GITBOOK} underline="none" target="_blank">
                <DocsIcon />
              </Link>
              </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: { xs: "left" },
              }}
            >
              <Link color="inherit" href={LINK_GITHUB} underline="none" target="_blank">
                <GithubIcon />
              </Link>
              </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center" marginBottom="1rem"
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}>
              <Typography variant="subtitle2">
                Write Us at &nbsp;
              </Typography>
              <Typography variant="subtitle2" sx={{ textDecoration: 'underline' }}>
                <a style={{ color: '#898989' }} href="mailto:karshapanadao@gmail.com">KarshaPanaDao@gmail.com</a>
              </Typography>
            </Grid>
            <Grid item xs={12} align="center"
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}>
              <Typography variant="caption">
                panadao.finance © 2022. Open-Sourced.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      );
    };
}