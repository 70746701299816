import { Component } from "react";
import Carousel from "react-material-ui-carousel";
import InsuranceProblems from "./Carousel-InsuranceProblems";
import InsuranceSolutions from "./Carousel-InsuranceSolutions";
import ExchangeSelfInsure from "./Carousel-ExchangeSelfInsure";
import HowPanaWorks_1 from "./Carousel-HowPanaWorks-1";
import HowPanaWorks_2 from "./Carousel-HowPanaWorks-2";
import HowPanaWorks_3 from "./Carousel-HowPanaWorks-3";
import PanaDAO from "./Carousel-PanaDAO";

export default class LandingCarousel extends Component {
  render() {
    return (
      <Carousel
        interval="20000"
        animation="slide"
        duration="1000"
        sx={{
          backgroundColor: "primary.contrastText",
          display: "flex",
          width: "100%",
          height: "100%",
          minHeight: "600px",
          flexDirection: "column",
          alignItems: "center",
          margin: 0,
          padding: 0,
        }}
      >
        <PanaDAO />
        <InsuranceProblems />
        <InsuranceSolutions />
        <ExchangeSelfInsure />
        <HowPanaWorks_1 />
        <HowPanaWorks_2 />
        <HowPanaWorks_3 />
      </Carousel>
    );
  };
}