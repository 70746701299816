import { Component } from 'react';
import Typography from '@mui/material/Typography';
import { Grid, Container, } from "@mui/material";
import InfoCard from 'src/components/InfoCard/InfoCard';

import { infoCards } from "../../views/Landing/landingData";

export default class Story extends Component {
    render() {
        return (
            <div id="story">
            <Container variant='secondary' maxWidth={false} disableGutters={true}>
                <Grid container variant="secondary-background" columns={10}>
                  <Grid item xs={12} sx={{ paddingBottom: 3, paddingLeft: 3, textAlign: "center"}}>
                    <Grid container sx={{ display: "contents" }}>
                      <Typography variant="h2" sx={{ display: 'inline', }}>So what's the&nbsp;</Typography>
                      <Typography variant="h2" sx={{ display: 'inline', color: 'primary.contrastText', }}>story?</Typography>
                    </Grid>
                  </Grid>
                    { infoCards.map((card, index) => (
                        <Grid data-aos="zoom-in" data-aos-duration="1500" item sm={10} md={5} lg={2} key={index}>
                            <InfoCard icon={card.icon} title={card.title} content={card.content} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
            </div>
        );
    }z
}