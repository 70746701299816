import React from 'react';
import { SvgIcon } from "@mui/material";
import { ReactComponent as Logo } from "../../assets/icons/badge_white.svg";

const DiscordIcon = () => (
  <SvgIcon sx={{ width: 70, height: 50, marginTop: 2, marginBottom: 2, }}>
    <Logo />
  </SvgIcon>
);

export default DiscordIcon;